var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"whole"},[_c('Nav'),_c('div',{staticClass:"catalog-whole desktop-present"},[_vm._m(0),_c('div',{staticClass:"shuhua-con"},[_c('div',{staticClass:"shortVideo"},_vm._l((_vm.fixtopicList),function(k,fixtopIndex){return _c('div',{key:'fixtopic_' + k.id,staticClass:"video-con"},[_c('div',{staticClass:"short-title"},[_c('div',{staticClass:"short-t",on:{"click":function($event){return _vm.onGoDoublePage(k.id)}}},[_vm._v(_vm._s(k.title))]),_c('div',{staticClass:"title-more",on:{"click":function($event){return _vm.onGoDoublePage(k.id)}}},[_vm._v("更多...")])]),_c('div',{staticClass:"short-con",class:{
              fixtopOne: k.typeOne,
              fixtopTwo: k.typeTwo,
              fixtopThree: k.typeThree,
            }},_vm._l((k.posts),function(short,shortindex){return _c('div',{key:short.id + 'shortvideo_' + shortindex,staticClass:"shortvideo-list"},[_c('video',{ref:'video_' + shortindex + '_' + short.id,refInFor:true,attrs:{"src":short.videoURL,"controls":"","loop":"","height":"523"},on:{"playing":function($event){return _vm.onVideoStatusChange('video_' + shortindex + '_' + short.id, $event)}}}),(short.posterShow)?_c('div',{staticClass:"shortvideo-img",style:({ backgroundImage: "url(" + short.imgURL + ")" }),on:{"mouseenter":function($event){return _vm.onNoposter(fixtopIndex, shortindex)}}}):_vm._e(),(short.posterShow)?_c('div',{staticClass:"shortvideo-title"},[_vm._v(_vm._s(short.title))]):_vm._e()])}),0)])}),0)])]),_c('div',{staticClass:"catalog-whole mobile-present"},[_vm._m(1),_c('div',{staticClass:"shuhua-con"},[_c('div',{staticClass:"shortVideo"},_vm._l((_vm.fixtopicList),function(k,fixtopIndex){return _c('div',{key:'fixtopic_' + k.id,staticClass:"video-con"},[_c('div',{staticClass:"short-title"},[_c('div',{staticClass:"short-t",on:{"click":function($event){return _vm.onGoDoublePage(k.id)}}},[_vm._v(_vm._s(k.title))]),_c('div',{staticClass:"title-more",on:{"click":function($event){return _vm.onGoDoublePage(k.id)}}},[_vm._v("更多...")])]),_c('div',{staticClass:"short-con",class:{
              fixtopOne: k.typeOne,
              fixtopTwo: k.typeTwo,
              fixtopThree: k.typeThree,
            }},_vm._l((k.posts),function(short,shortindex){return _c('div',{key:short.id + 'shortvideo_' + shortindex,staticClass:"shortvideo-list"},[_c('video',{ref:'video_' + shortindex + '_' + short.id,refInFor:true,attrs:{"src":short.videoURL,"controls":"","loop":"","height":"523"},on:{"playing":function($event){return _vm.onVideoStatusChange('video_' + shortindex + '_' + short.id, $event)}}}),(short.posterShow)?_c('div',{staticClass:"shortvideo-img",style:({ backgroundImage: "url(" + short.imgURL + ")" }),on:{"mouseenter":function($event){return _vm.onNoposter(fixtopIndex, shortindex)}}}):_vm._e(),(short.posterShow)?_c('div',{staticClass:"shortvideo-title"},[_vm._v(_vm._s(short.title))]):_vm._e()])}),0)])}),0)])]),_c('Bottom')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"catalogtitle"},[_c('div',{staticClass:"icon"}),_vm._v(" 首页>>视频专区 ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"catalogtitle"},[_c('div',{staticClass:"icon"}),_vm._v(" 首页>>视频专区 ")])}]

export { render, staticRenderFns }