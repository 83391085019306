<template>
  <div class="whole">
    <Nav></Nav>
    <div class="catalog-whole desktop-present">
      <div class="catalogtitle">
        <div class="icon"></div>
        首页>>视频专区
      </div>
      <!-- 视频 -->
      <div class="shuhua-con">
        <!-- 短视频 -->
        <div class="shortVideo">
          <div class="video-con" v-for="(k, fixtopIndex) in fixtopicList" :key="'fixtopic_' + k.id">
            <div class="short-title">
              <div class="short-t" @click="onGoDoublePage(k.id)">{{ k.title }}</div>
              <div class="title-more" @click="onGoDoublePage(k.id)">更多...</div>
            </div>
            <div
              class="short-con"
              v-bind:class="{
                fixtopOne: k.typeOne,
                fixtopTwo: k.typeTwo,
                fixtopThree: k.typeThree,
              }"
            >
              <div class="shortvideo-list" v-for="(short, shortindex) in k.posts" :key="short.id + 'shortvideo_' + shortindex">
                <video
                  :src="short.videoURL"
                  controls
                  :ref="'video_' + shortindex + '_' + short.id"
                  @playing="onVideoStatusChange('video_' + shortindex + '_' + short.id, $event)"
                  loop
                  height="523"
                ></video>
                <div
                  class="shortvideo-img"
                  v-if="short.posterShow"
                  :style="{ backgroundImage: `url(` + short.imgURL + `)` }"
                  @mouseenter="onNoposter(fixtopIndex, shortindex)"
                ></div>
                <div class="shortvideo-title" v-if="short.posterShow">{{ short.title }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="catalog-whole mobile-present">
      <div class="catalogtitle">
        <div class="icon"></div>
        首页>>视频专区
      </div>
      <!-- 视频 -->
      <div class="shuhua-con">
        <!-- 短视频 -->
        <div class="shortVideo">
          <div class="video-con" v-for="(k, fixtopIndex) in fixtopicList" :key="'fixtopic_' + k.id">
            <div class="short-title">
              <div class="short-t" @click="onGoDoublePage(k.id)">{{ k.title }}</div>
              <div class="title-more" @click="onGoDoublePage(k.id)">更多...</div>
            </div>
            <div
              class="short-con"
              v-bind:class="{
                fixtopOne: k.typeOne,
                fixtopTwo: k.typeTwo,
                fixtopThree: k.typeThree,
              }"
            >
              <div class="shortvideo-list" v-for="(short, shortindex) in k.posts" :key="short.id + 'shortvideo_' + shortindex">
                <video
                  :src="short.videoURL"
                  controls
                  :ref="'video_' + shortindex + '_' + short.id"
                  @playing="onVideoStatusChange('video_' + shortindex + '_' + short.id, $event)"
                  loop
                  height="523"
                ></video>
                <div
                  class="shortvideo-img"
                  v-if="short.posterShow"
                  :style="{ backgroundImage: `url(` + short.imgURL + `)` }"
                  @mouseenter="onNoposter(fixtopIndex, shortindex)"
                ></div>
                <div class="shortvideo-title" v-if="short.posterShow">{{ short.title }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
var timers = []
import RD from '@/api/RD'
import Nav from '@/components/nav.vue'
import Bottom from '@/components/bottom.vue'

export default {
  components: { Nav, Bottom },
  data: function () {
    return {
      fixtopicList: [], // 栏目的列表
      picIndex: 0, // 书画轮播
    }
  },
  created() {
    this.reload()
  },
  beforeRouteUpdate(to, from, next) {
    if (to.fullPath != from.fullPath) {
      next()
      this.reload()
    }
  },
  methods: {
    reload() {
      var thiz = this
      console.log('quet', thiz.$route.query)

      RD.fixedTopic()
        .all(2)
        .then((data) => {
          console.log('catalogdata', data)
          if (data.length == 0) {
            thiz.$message('当前栏目无稿件')
          }
          data.forEach((i, index) => {
            i.posts = []
            var indexOrder = index + 1
            if (indexOrder % 3 == 1) {
              i.typeOne = true
              i.typeTwo = false
              i.typeThree = false
            } else if (indexOrder % 3 == 2) {
              i.typeTwo = true
              i.typeOne = false
              i.typeThree = false
            } else if (indexOrder % 3 == 0) {
              i.typeThree = true
              i.typeTwo = false
              i.typeOne = false
            }
            thiz.postList(i.id, i)
          })
          thiz.fixtopicList = data
          console.log('posts', thiz.fixtopicList)
        })
    },
    postList(id, item) {
      var thiz = this
      RD.fixedTopic()
        .id(id)
        .posts()
        .then((data) => {
          data.list.forEach((k) => {
            k.imgURL = RD.video().id(k.id).poster_URL()
            k.videoURL = RD.video().id(k.id).svideo_URL()
            k.posterShow = true
          })
          item.posts = data.list
        })
    },
    stopTimer() {
      clearInterval(timers)
    },
    restartTimer() {
      timers = setInterval(() => {
        this.swipernext()
      }, 3000)
    },
    onLookPost(picId) {
      var postId = this.fixtopicList[0].posts[picId].postId
      this.$router.push({
        path: '/post.html',
        query: { id: postId },
      })
    },
    // 进入二级页面
    onGoDoublePage(postId) {
      console.log('jkk', postId)
      this.$router.push({
        path: '/fixTopic.html',
        query: { id: postId },
      })
    },
    // 鼠标移入
    onNoposter(listindex, videoIndex) {
      this.fixtopicList[listindex].posts[videoIndex].posterShow = false
    },
    // 播放状态
    onVideoStatusChange(currentplay, event) {
      var thiz = this
      console.log('event', event)
      for (var key in thiz.$refs) {
        if (key.substr(0, 6) == 'video_') {
          if (currentplay == key) {
            // 当前正在播放的
            console.log('currentplay')
          } else {
            // 其他的都暂停
            console.log('sibliingpause', thiz.$refs, thiz.$refs[key])
            thiz.$refs[key][0].pause()
          }
        }
      }
    },
  },
}
</script>

<style scoped lang="less">
.catalog-whole.desktop-present {
  min-height: calc(100vh - 25.9rem);
  background-color: #fff;
  min-height: 61.6rem;
  .catalogtitle {
    height: 4rem;
    text-align: left;
    width: 120rem;
    margin: 0 auto;
    font-size: 1.6rem;
    padding-top: 1.5rem;
    display: flex;
    border-bottom: 0.1rem solid #bfbfbf;
    .icon {
      width: 2rem;
      height: 2rem;
      background: no-repeat url(../assets/weizhi.png);
      background-size: cover;
      margin-right: 1rem;
    }
  }
  .shuhua-con {
    width: 120rem;
    margin: 3rem auto;
    height: 100%;
    overflow: hidden;
    .shortVideo {
      width: 120rem;
      margin: 1rem auto;
      .short-title {
        height: 6rem;
        line-height: 6rem;
        font-size: 2rem;
        position: relative;
        .short-t {
          position: absolute;
          left: 48%;
          top: 0rem;
          cursor: pointer;
        }
        .title-more {
          position: absolute;
          right: 1rem;
          top: 1rem;
          font-size: 1.3rem;
          cursor: pointer;
        }
      }
      .short-con {
        height: 51.4rem;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        position: relative;
      }
      .fixtopOne {
        display: flex;
        justify-content: space-between;
        height: 30rem;
        .shortvideo-list {
          width: 39.2rem;
          height: 23.6rem;
          position: relative;
          video {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .shortvideo-img {
            position: absolute;
            left: 0rem;
            top: 0rem;
            width: 100%;
            height: 100%;
            background: no-repeat 50% / cover;
          }
          .shortvideo-title {
            position: absolute;
            bottom: 0rem;
            left: 0rem;
            width: 100%;
            height: 4rem;
            line-height: 4rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            background-color: rgba(0, 0, 0, 0.3);
            color: #fff;
          }
        }
        .shortvideo-list:nth-child(1n + 4) {
          display: none;
        }
      }
      .fixtopTwo {
        .shortvideo-list:nth-child(1) {
          width: 50%;
          height: 50rem;
        }
        .shortvideo-list {
          width: 28.5rem;
          height: 23.6rem;
          margin-right: 1rem;
          position: relative;
          video {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .shortvideo-img {
            position: absolute;
            left: 0rem;
            top: 0rem;
            width: 100%;
            height: 100%;
            background: no-repeat 50% / cover;
          }
          .shortvideo-title {
            position: absolute;
            bottom: 0rem;
            left: 0rem;
            width: 100%;
            height: 4rem;
            line-height: 4rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            background-color: rgba(0, 0, 0, 0.3);
            color: #fff;
          }
        }
        .shortvideo-list:nth-child(4) {
          position: absolute;
          top: 26.3rem;
          left: 61.1rem;
        }
        .shortvideo-list:nth-child(5) {
          position: absolute;
          top: 26.3rem;
          left: 90.6rem;
        }
        .shortvideo-list:nth-child(1n + 6) {
          display: none;
        }
      }
      .fixtopThree {
        .shortvideo-list {
          video {
            width: 100%;
            height: 100%;
          }
        }
        .shortvideo-list:nth-child(1) {
          width: 29.3rem;
          height: 21.6rem;
          position: absolute;
          top: 0rem;
          left: 0rem;
        }
        .shortvideo-list:nth-child(2) {
          width: 29.3rem;
          height: 21.6rem;
          position: absolute;
          top: 0rem;
          left: 30.3rem;
        }
        .shortvideo-list:nth-child(3) {
          width: 29.3rem;
          height: 21.6rem;
          position: absolute;
          top: 22.6rem;
          left: 0rem;
        }
        .shortvideo-list:nth-child(4) {
          width: 29.3rem;
          height: 21.6rem;
          position: absolute;
          top: 22.6rem;
          left: 30.3rem;
        }
        .shortvideo-list:nth-child(5) {
          width: 59.6rem;
          height: 44.1rem;
          position: absolute;
          top: 0rem;
          left: 60.6rem;
        }
        .shortvideo-list:nth-child(1n + 6) {
          display: none;
        }
      }
    }
  }
}
.catalog-whole.mobile-present {
  height: auto;
  background-color: #fff;
  min-height: 800px;
  .catalogtitle {
    height: 40px;
    text-align: left;
    width: 750px;
    margin: 0 auto;
    font-size: 16px;
    padding-top: 15px;
    display: flex;
    border-bottom: 0.1rem solid #bfbfbf;
    .icon {
      width: 20px;
      height: 20px;
      background: no-repeat url(../assets/weizhi.png);
      background-size: cover;
      margin-right: 10px;
    }
  }
  .shuhua-con {
    width: 750px;
    margin: 30px auto;
    height: 100%;
    overflow: hidden;
    .shortVideo {
      width: 120rem;
      margin: 1rem auto;
      .short-title {
        height: 6rem;
        line-height: 6rem;
        font-size: 2rem;
        position: relative;
        .short-t {
          position: absolute;
          left: 48%;
          top: 0rem;
          cursor: pointer;
        }
        .title-more {
          position: absolute;
          right: 1rem;
          top: 1rem;
          font-size: 1.3rem;
          cursor: pointer;
        }
      }
      .short-con {
        height: 51.4rem;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        position: relative;
      }
      .fixtopOne {
        display: flex;
        justify-content: space-between;
        height: 30rem;
        .shortvideo-list {
          width: 39.2rem;
          height: 23.6rem;
          video {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .shortvideo-list:nth-child(1n + 4) {
          display: none;
        }
      }
      .fixtopTwo {
        .shortvideo-list:nth-child(1) {
          width: 50%;
          height: 50rem;
        }
        .shortvideo-list {
          width: 28.5rem;
          height: 23.6rem;
          margin-right: 1rem;
          position: relative;
          video {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .shortvideo-img {
            position: absolute;
            left: 0rem;
            top: 0rem;
            width: 100%;
            height: 100%;
            background: no-repeat 50% / cover;
          }
          .shortvideo-title {
            position: absolute;
            bottom: 0rem;
            left: 0rem;
            width: 100%;
            height: 4rem;
            line-height: 4rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            background-color: rgba(0, 0, 0, 0.3);
            color: #fff;
          }
        }
        .shortvideo-list:nth-child(4) {
          position: absolute;
          top: 26.3rem;
          left: 61.1rem;
        }
        .shortvideo-list:nth-child(5) {
          position: absolute;
          top: 26.3rem;
          left: 90.6rem;
        }
        .shortvideo-list:nth-child(1n + 6) {
          display: none;
        }
      }
      .fixtopThree {
        .shortvideo-list:nth-child(1) {
          width: 29.3rem;
          height: 21.6rem;
          position: absolute;
          top: 0rem;
          left: 0rem;
        }
        .shortvideo-list:nth-child(2) {
          width: 29.3rem;
          height: 21.6rem;
          position: absolute;
          top: 0rem;
          left: 30.3rem;
        }
        .shortvideo-list:nth-child(3) {
          width: 29.3rem;
          height: 21.6rem;
          position: absolute;
          top: 22.6rem;
          left: 0rem;
        }
        .shortvideo-list:nth-child(4) {
          width: 29.3rem;
          height: 21.6rem;
          position: absolute;
          top: 22.6rem;
          left: 30.3rem;
        }
        .shortvideo-list:nth-child(5) {
          width: 59.6rem;
          height: 44.1rem;
          position: absolute;
          top: 0rem;
          left: 60.6rem;
        }
        .shortvideo-list:nth-child(1n + 6) {
          display: none;
        }
      }
    }
  }
}
</style>
